// extracted by mini-css-extract-plugin
export var active = "fullWidthImage-module--active--c4ec8";
export var bigWhite = "fullWidthImage-module--big-white--3b9f1";
export var blue = "fullWidthImage-module--blue--a9e38";
export var blueBg = "fullWidthImage-module--blue-bg--6ec34";
export var center = "fullWidthImage-module--center--8ee51";
export var cookie__customise = "fullWidthImage-module--cookie__customise--09a98";
export var cookie__customise__buttons = "fullWidthImage-module--cookie__customise__buttons--74cf6";
export var cookie__customise__buttons__allow = "fullWidthImage-module--cookie__customise__buttons__allow--563c2";
export var cookie__customise__buttons__disable = "fullWidthImage-module--cookie__customise__buttons__disable--567e1";
export var cookie__customise__buttons__save = "fullWidthImage-module--cookie__customise__buttons__save--cdb39";
export var cookie__customise__close = "fullWidthImage-module--cookie__customise__close--d1bad";
export var cookie__customise__content = "fullWidthImage-module--cookie__customise__content--69b5e";
export var cookie__customise__content__title = "fullWidthImage-module--cookie__customise__content__title--242fd";
export var cookie__customise__overlay = "fullWidthImage-module--cookie__customise__overlay--3c214";
export var cookie__popup = "fullWidthImage-module--cookie__popup--d4aee";
export var cookie__popup__button = "fullWidthImage-module--cookie__popup__button--468e6";
export var cookie__popup__close = "fullWidthImage-module--cookie__popup__close--a60c5";
export var cookie__popup__content = "fullWidthImage-module--cookie__popup__content--24afa";
export var cookie__popup__text = "fullWidthImage-module--cookie__popup__text--2bf80";
export var decreaseHeight = "fullWidthImage-module--decrease-height--3bc6d";
export var embed_responsive = "fullWidthImage-module--embed_responsive--47c64";
export var embed_responsive_16by9 = "fullWidthImage-module--embed_responsive_16by9--d031f";
export var embed_responsive_item = "fullWidthImage-module--embed_responsive_item--7deeb";
export var flexUl = "fullWidthImage-module--flex-ul--9fe7b";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "fullWidthImage-module--fullWidthCta-module--fullWidthCta--fdc73--00ceb";
export var fullWidthImage = "fullWidthImage-module--fullWidthImage--b32e8";
export var fullWidthTextModuleIntroText2b0e4 = "fullWidthImage-module--fullWidthText-module--introText--2b0e4--9a0a2";
export var header = "fullWidthImage-module--header--4b64a";
export var iframeContainer = "fullWidthImage-module--iframe-container--1f1bc";
export var indicationSection = "fullWidthImage-module--indication-section--d1ab9";
export var left = "fullWidthImage-module--left--0c096";
export var overlay = "fullWidthImage-module--overlay--c9f1f";
export var removeMobile = "fullWidthImage-module--remove-mobile--6c072";
export var responsiveIframe = "fullWidthImage-module--responsive-iframe--363b0";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "fullWidthImage-module--secondLevelHero-module--banner__subTitle--8b2b5--15aeb";
export var singleTimestamp = "fullWidthImage-module--single-timestamp--1d3e8";
export var slider = "fullWidthImage-module--slider--ebe40";
export var small = "fullWidthImage-module--small--bedcd";
export var standard = "fullWidthImage-module--standard--2452a";
export var switcher = "fullWidthImage-module--switcher--b7460";
export var timestamps = "fullWidthImage-module--timestamps--0d021";
export var videoArea = "fullWidthImage-module--videoArea--f36ad";
export var videoResource = "fullWidthImage-module--video-resource--076d9";
export var videoTime = "fullWidthImage-module--videoTime--76e7a";
export var video__iframe = "fullWidthImage-module--video__iframe--8bd2c";
export var yell = "fullWidthImage-module--yell--aa7c1";