
import React from "react"
import * as styles from "./imageCols.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function imageCols({ data }) {
  return (
    <div className={styles.imageCols}>
      <div className={styles.imageCols__content}>
        <GatsbyImage className={styles.imageCols__content__image} image={data.leftImage} />
        <div className={styles.imageCols__content__text} dangerouslySetInnerHTML={{ __html: data.leftText.childMarkdownRemark.html }}/>
      </div>

      <div className={styles.imageCols__content}>
        <GatsbyImage className={styles.imageCols__content__image} image={data.rightImage} />
        <div className={styles.imageCols__content__text} dangerouslySetInnerHTML={{ __html: data.rightText.childMarkdownRemark.html }}/>
      </div>
    </div>
  )
}

export default imageCols