// extracted by mini-css-extract-plugin
export var active = "videoBanner-module--active--ba208";
export var bigWhite = "videoBanner-module--big-white--81eb7";
export var blue = "videoBanner-module--blue--35cd7";
export var blueBg = "videoBanner-module--blue-bg--8bfd8";
export var center = "videoBanner-module--center--b6aba";
export var cookie__customise = "videoBanner-module--cookie__customise--6a2da";
export var cookie__customise__buttons = "videoBanner-module--cookie__customise__buttons--5f2c6";
export var cookie__customise__buttons__allow = "videoBanner-module--cookie__customise__buttons__allow--e945f";
export var cookie__customise__buttons__disable = "videoBanner-module--cookie__customise__buttons__disable--06a8d";
export var cookie__customise__buttons__save = "videoBanner-module--cookie__customise__buttons__save--e7665";
export var cookie__customise__close = "videoBanner-module--cookie__customise__close--1cecc";
export var cookie__customise__content = "videoBanner-module--cookie__customise__content--02db7";
export var cookie__customise__content__title = "videoBanner-module--cookie__customise__content__title--83f2f";
export var cookie__customise__overlay = "videoBanner-module--cookie__customise__overlay--27952";
export var cookie__popup = "videoBanner-module--cookie__popup--fb4d4";
export var cookie__popup__button = "videoBanner-module--cookie__popup__button--e790b";
export var cookie__popup__close = "videoBanner-module--cookie__popup__close--764d8";
export var cookie__popup__content = "videoBanner-module--cookie__popup__content--cd022";
export var cookie__popup__text = "videoBanner-module--cookie__popup__text--79528";
export var decreaseHeight = "videoBanner-module--decrease-height--e4d96";
export var embed_responsive = "videoBanner-module--embed_responsive--74e69";
export var embed_responsive_16by9 = "videoBanner-module--embed_responsive_16by9--729ff";
export var embed_responsive_item = "videoBanner-module--embed_responsive_item--a7f50";
export var flexUl = "videoBanner-module--flex-ul--d0352";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "videoBanner-module--fullWidthCta-module--fullWidthCta--fdc73--48f5f";
export var fullWidthTextModuleIntroText2b0e4 = "videoBanner-module--fullWidthText-module--introText--2b0e4--02a6c";
export var header = "videoBanner-module--header--24bea";
export var iframeContainer = "videoBanner-module--iframe-container--b6019";
export var indicationSection = "videoBanner-module--indication-section--3670d";
export var left = "videoBanner-module--left--204a6";
export var overlay = "videoBanner-module--overlay--8319e";
export var removeMobile = "videoBanner-module--remove-mobile--a4c0d";
export var responsiveIframe = "videoBanner-module--responsive-iframe--e0770";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "videoBanner-module--secondLevelHero-module--banner__subTitle--8b2b5--fbf8c";
export var singleTimestamp = "videoBanner-module--single-timestamp--36d84";
export var slider = "videoBanner-module--slider--8cd2d";
export var small = "videoBanner-module--small--148e6";
export var standard = "videoBanner-module--standard--121a4";
export var switcher = "videoBanner-module--switcher--091f8";
export var timestamps = "videoBanner-module--timestamps--8a7f1";
export var videoArea = "videoBanner-module--videoArea--68ba8";
export var videoBanner = "videoBanner-module--videoBanner--ee9db";
export var videoBanner__item = "videoBanner-module--videoBanner__item--f36fd";
export var videoBanner__item__content = "videoBanner-module--videoBanner__item__content--f1ed7";
export var videoBanner__item__content__text = "videoBanner-module--videoBanner__item__content__text--bf0e7";
export var videoBanner__item__content__video = "videoBanner-module--videoBanner__item__content__video--ada8a";
export var videoResource = "videoBanner-module--video-resource--dac83";
export var videoTime = "videoBanner-module--videoTime--e236d";
export var video__iframe = "videoBanner-module--video__iframe--f6b04";
export var yell = "videoBanner-module--yell--8f5dd";