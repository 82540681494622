
import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import ImageCols from "../../components/Global/imageCols/imageCols"
import VideoBanner from "../../components/Global/videoBanner/videoBanner"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"

const MOAPage = ({ data }) => {

  const content = data.allContentfulMoaPage.nodes[0];

  let heroData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
    backPos: "top right"
  }

  let imageColsData = {
    leftImage: getImage(content.leftImage),
    leftText: content.leftText,
    rightImage: getImage(content.rightImage),
    rightText: content.rightText
  }

  let videoBannerData = {
    text: content.videoBanner.content,
    buttonText: content.videoBanner.buttonText,
    buttonDestination: content.videoBanner.buttonDestination,
    videoUrl: content.videoBanner.videoUrl,
    showButton: false,
  }

  let fullWidthCtaData = {
    title: content.relatedPages.title,
    buttons: content.relatedPages.buttons,
    customClass: "mt-5"
  }

  let fullWidthBannerData = {
    imageTitle: content.fullWidthBanner.image.title,
    image: getImage(content.fullWidthBanner.image),
  }
  
  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: content.footerData,
  }

  return (
    <Layout>
      <Seo title="QARZIBAS Mechanism of action" />
      <Hero data={heroData}/>
      <ImageCols data={imageColsData} />      
      <VideoBanner data={videoBannerData} />
      <FullWidthCTA data={fullWidthCtaData} />
      <FullWidthImage data={fullWidthBannerData} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulMoaPage {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        leftImage {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
          title
        }
        leftText {
          childMarkdownRemark {
            html
          }
        }
        rightImage {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
          title
        }
        rightText {
          childMarkdownRemark {
            html
          }
        }
        videoBanner {
          content {
            childMarkdownRemark {
              html
            }
          }
          buttonText {
            childMarkdownRemark {
              html
            }
          }
          buttonDestination
          videoUrl
        }
        fullWidthBanner {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        relatedPages {
          title {
            childMarkdownRemark {
              html
            }
          }
          buttons {
            buttonText {
              childMarkdownRemark {
                html
              }
            }
            buttonUrl
          }
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`

export default MOAPage