// extracted by mini-css-extract-plugin
export var active = "secondLevelHero-module--active--1c711";
export var banner = "secondLevelHero-module--banner--a72b7";
export var banner__image = "secondLevelHero-module--banner__image--d1d20";
export var banner__subTitle = "secondLevelHero-module--banner__subTitle--8b2b5";
export var banner__text = "secondLevelHero-module--banner__text--961ee";
export var banner__text__breadcrumbs = "secondLevelHero-module--banner__text__breadcrumbs--8d31e";
export var banner__text__breadcrumbs__item = "secondLevelHero-module--banner__text__breadcrumbs__item--7c611";
export var banner__title = "secondLevelHero-module--banner__title--3cc05";
export var bigWhite = "secondLevelHero-module--big-white--532bd";
export var blue = "secondLevelHero-module--blue--75660";
export var blueBg = "secondLevelHero-module--blue-bg--1d8ed";
export var center = "secondLevelHero-module--center--7904e";
export var cookie__customise = "secondLevelHero-module--cookie__customise--844ea";
export var cookie__customise__buttons = "secondLevelHero-module--cookie__customise__buttons--8aa66";
export var cookie__customise__buttons__allow = "secondLevelHero-module--cookie__customise__buttons__allow--52b37";
export var cookie__customise__buttons__disable = "secondLevelHero-module--cookie__customise__buttons__disable--c083b";
export var cookie__customise__buttons__save = "secondLevelHero-module--cookie__customise__buttons__save--bfb6d";
export var cookie__customise__close = "secondLevelHero-module--cookie__customise__close--7c3f2";
export var cookie__customise__content = "secondLevelHero-module--cookie__customise__content--1ff1e";
export var cookie__customise__content__title = "secondLevelHero-module--cookie__customise__content__title--b63ce";
export var cookie__customise__overlay = "secondLevelHero-module--cookie__customise__overlay--f9fc4";
export var cookie__popup = "secondLevelHero-module--cookie__popup--11b2f";
export var cookie__popup__button = "secondLevelHero-module--cookie__popup__button--5f17e";
export var cookie__popup__close = "secondLevelHero-module--cookie__popup__close--92158";
export var cookie__popup__content = "secondLevelHero-module--cookie__popup__content--31a22";
export var cookie__popup__text = "secondLevelHero-module--cookie__popup__text--7a08f";
export var decreaseHeight = "secondLevelHero-module--decrease-height--02441";
export var embed_responsive = "secondLevelHero-module--embed_responsive--8d7d4";
export var embed_responsive_16by9 = "secondLevelHero-module--embed_responsive_16by9--4fbcb";
export var embed_responsive_item = "secondLevelHero-module--embed_responsive_item--a7231";
export var flexUl = "secondLevelHero-module--flex-ul--827b1";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "secondLevelHero-module--fullWidthCta-module--fullWidthCta--fdc73--10dd4";
export var fullWidthTextModuleIntroText2b0e4 = "secondLevelHero-module--fullWidthText-module--introText--2b0e4--03809";
export var header = "secondLevelHero-module--header--60303";
export var iframeContainer = "secondLevelHero-module--iframe-container--847de";
export var indicationSection = "secondLevelHero-module--indication-section--1e51d";
export var left = "secondLevelHero-module--left--67429";
export var overlay = "secondLevelHero-module--overlay--05191";
export var removeMobile = "secondLevelHero-module--remove-mobile--58fcf";
export var responsiveIframe = "secondLevelHero-module--responsive-iframe--53e78";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "secondLevelHero-module--secondLevelHero-module--banner__subTitle--8b2b5--2229b";
export var singleTimestamp = "secondLevelHero-module--single-timestamp--fd94e";
export var slider = "secondLevelHero-module--slider--2a354";
export var small = "secondLevelHero-module--small--b4ea7";
export var standard = "secondLevelHero-module--standard--14d17";
export var switcher = "secondLevelHero-module--switcher--418a5";
export var timestamps = "secondLevelHero-module--timestamps--cfeeb";
export var videoArea = "secondLevelHero-module--videoArea--692da";
export var videoResource = "secondLevelHero-module--video-resource--ec4e7";
export var videoTime = "secondLevelHero-module--videoTime--178aa";
export var video__iframe = "secondLevelHero-module--video__iframe--33a8a";
export var yell = "secondLevelHero-module--yell--b06bc";