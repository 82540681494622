import React from "react"
import Button from "../button/button"
import * as styles from "./fullWidthCta.module.scss"

function FullWidthCta({ data }) {

  const buttons = data.buttons.map(button => {
    let ButtonData = {
      slug: button.buttonUrl,
      text: button.buttonText
    }

    return (
      <Button className={styles.fullWidthCta__buttons__button} data={ButtonData} />
    )
  })

  return (
    <div className={data.customClass ? `${data.customClass} ${styles.fullWidthCta}` : `${styles.fullWidthCta}`}>
      <div className={styles.fullWidthCta__title} dangerouslySetInnerHTML={{ __html: data.title.childMarkdownRemark.html }} />
      <div className={styles.fullWidthCta__buttons}>
        {buttons}
      </div>
    </div>
  )
}

export default FullWidthCta;