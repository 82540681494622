import React from "react"
import * as styles from "./video.module.scss"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={`${styles.embed_responsive} ${styles.embed_responsive_16by9}`}>
    <iframe
      className={`${styles.video__iframe}${styles.embed_responsive_item}`}
      src={videoSrcURL}
      title={videoTitle}
      allow="encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default Video
