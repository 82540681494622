// extracted by mini-css-extract-plugin
export var active = "fullWidthCta-module--active--671a2";
export var bigWhite = "fullWidthCta-module--big-white--7ffa8";
export var blue = "fullWidthCta-module--blue--eaf1c";
export var blueBg = "fullWidthCta-module--blue-bg--3e35f";
export var center = "fullWidthCta-module--center--9ccc0";
export var cookie__customise = "fullWidthCta-module--cookie__customise--64bd3";
export var cookie__customise__buttons = "fullWidthCta-module--cookie__customise__buttons--22b38";
export var cookie__customise__buttons__allow = "fullWidthCta-module--cookie__customise__buttons__allow--92012";
export var cookie__customise__buttons__disable = "fullWidthCta-module--cookie__customise__buttons__disable--47cde";
export var cookie__customise__buttons__save = "fullWidthCta-module--cookie__customise__buttons__save--11017";
export var cookie__customise__close = "fullWidthCta-module--cookie__customise__close--e0416";
export var cookie__customise__content = "fullWidthCta-module--cookie__customise__content--82847";
export var cookie__customise__content__title = "fullWidthCta-module--cookie__customise__content__title--dfd34";
export var cookie__customise__overlay = "fullWidthCta-module--cookie__customise__overlay--0b55d";
export var cookie__popup = "fullWidthCta-module--cookie__popup--039a3";
export var cookie__popup__button = "fullWidthCta-module--cookie__popup__button--14152";
export var cookie__popup__close = "fullWidthCta-module--cookie__popup__close--0997a";
export var cookie__popup__content = "fullWidthCta-module--cookie__popup__content--887d9";
export var cookie__popup__text = "fullWidthCta-module--cookie__popup__text--0eaf6";
export var decreaseHeight = "fullWidthCta-module--decrease-height--5d7e1";
export var embed_responsive = "fullWidthCta-module--embed_responsive--c2b49";
export var embed_responsive_16by9 = "fullWidthCta-module--embed_responsive_16by9--0c5e0";
export var embed_responsive_item = "fullWidthCta-module--embed_responsive_item--a6382";
export var flexUl = "fullWidthCta-module--flex-ul--c91b9";
export var fullWidthCta = "fullWidthCta-module--fullWidthCta--fdc73";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "fullWidthCta-module--fullWidthCta-module--fullWidthCta--fdc73--7069a";
export var fullWidthCta__buttons = "fullWidthCta-module--fullWidthCta__buttons--00986";
export var fullWidthCta__title = "fullWidthCta-module--fullWidthCta__title--2dbea";
export var fullWidthTextModuleIntroText2b0e4 = "fullWidthCta-module--fullWidthText-module--introText--2b0e4--9c141";
export var header = "fullWidthCta-module--header--9967b";
export var iframeContainer = "fullWidthCta-module--iframe-container--7bac1";
export var indicationSection = "fullWidthCta-module--indication-section--886b8";
export var left = "fullWidthCta-module--left--01961";
export var overlay = "fullWidthCta-module--overlay--f09d4";
export var removeMobile = "fullWidthCta-module--remove-mobile--f893a";
export var responsiveIframe = "fullWidthCta-module--responsive-iframe--d42d2";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "fullWidthCta-module--secondLevelHero-module--banner__subTitle--8b2b5--01e88";
export var singleTimestamp = "fullWidthCta-module--single-timestamp--23c6f";
export var slider = "fullWidthCta-module--slider--3bc4c";
export var small = "fullWidthCta-module--small--fffba";
export var standard = "fullWidthCta-module--standard--7a01f";
export var switcher = "fullWidthCta-module--switcher--393f6";
export var timestamps = "fullWidthCta-module--timestamps--e5ff0";
export var videoArea = "fullWidthCta-module--videoArea--c7972";
export var videoResource = "fullWidthCta-module--video-resource--fef81";
export var videoTime = "fullWidthCta-module--videoTime--d3b28";
export var video__iframe = "fullWidthCta-module--video__iframe--56bc8";
export var yell = "fullWidthCta-module--yell--7e7e8";