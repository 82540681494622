import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import * as styles from "./secondLevelHero.module.scss"

function secondLevelBanner({ data }) {

  const image = getImage(data.backgroundImage)
  const bgImage = convertToBgImage(image)

  const BreadcrumbsList = data.breadcrumbs.map(breadcrumb => {
    if(breadcrumb.url) {
      return (  
        <Link className={styles.banner__text__breadcrumbs__item} dangerouslySetInnerHTML={{ __html: breadcrumb.title }} to={breadcrumb.url}></Link>
      )
    } else {
      return (  
        <div className={styles.banner__text__breadcrumbs__item} dangerouslySetInnerHTML={{ __html: breadcrumb.title }} />
      )
    }
  });

  return (
    <div className={styles.banner}>
        <BackgroundImage className={styles.banner__image}
          {...bgImage}
          preserveStackingContext
          alt={data.backgroundImage.title}
          style={{
            backgroundPosition: data.backPos,
        }}>
          <div className={styles.banner__text}>
            <div className={styles.banner__text__breadcrumbs}>
              {BreadcrumbsList}
            </div>
            <h1 className={styles.banner__title}>{data.title}</h1>
            <div className={styles.banner__subTitle} dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }} />
          </div>
        </BackgroundImage>
    </div>
  )

}

export default secondLevelBanner