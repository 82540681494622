import React from "react"
import * as styles from "./videoBanner.module.scss"
import Button from "../button/button"
import Video from "../video/video"

function videoBanner({ data }) {

  let ButtonData = {
    slug: data.buttonDestination,
    text: data.buttonText
  }

  return (
    <div className={styles.videoBanner}>
      <div className={styles.videoBanner__item}>
        <div className={styles.videoBanner__item__content}>
  
          <div className={styles.videoBanner__item__content__text}>
            <div dangerouslySetInnerHTML={{ __html: data.text.childMarkdownRemark.html }} />
            {data.showButton && <Button className={styles.callToAction__button} data={ButtonData} />}
          </div>
  
          <div className={styles.videoBanner__item__content__video}>
            <Video videoSrcURL={data.videoUrl} />
          </div>
  
        </div>
      </div>
    </div>
  )
}

export default videoBanner