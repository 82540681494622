// extracted by mini-css-extract-plugin
export var active = "video-module--active--9c319";
export var bigWhite = "video-module--big-white--ba407";
export var blue = "video-module--blue--1c296";
export var blueBg = "video-module--blue-bg--472f9";
export var center = "video-module--center--9912b";
export var cookie__customise = "video-module--cookie__customise--9094f";
export var cookie__customise__buttons = "video-module--cookie__customise__buttons--fafc1";
export var cookie__customise__buttons__allow = "video-module--cookie__customise__buttons__allow--2c15a";
export var cookie__customise__buttons__disable = "video-module--cookie__customise__buttons__disable--40927";
export var cookie__customise__buttons__save = "video-module--cookie__customise__buttons__save--253be";
export var cookie__customise__close = "video-module--cookie__customise__close--c98f0";
export var cookie__customise__content = "video-module--cookie__customise__content--e3338";
export var cookie__customise__content__title = "video-module--cookie__customise__content__title--e2a1a";
export var cookie__customise__overlay = "video-module--cookie__customise__overlay--7bafb";
export var cookie__popup = "video-module--cookie__popup--30813";
export var cookie__popup__button = "video-module--cookie__popup__button--52116";
export var cookie__popup__close = "video-module--cookie__popup__close--0bd0d";
export var cookie__popup__content = "video-module--cookie__popup__content--6f381";
export var cookie__popup__text = "video-module--cookie__popup__text--7fc64";
export var decreaseHeight = "video-module--decrease-height--26dab";
export var embed_responsive = "video-module--embed_responsive--9b90f";
export var embed_responsive_16by9 = "video-module--embed_responsive_16by9--3a7d2";
export var embed_responsive_item = "video-module--embed_responsive_item--f8d66";
export var flexUl = "video-module--flex-ul--5cc38";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "video-module--fullWidthCta-module--fullWidthCta--fdc73--93913";
export var fullWidthTextModuleIntroText2b0e4 = "video-module--fullWidthText-module--introText--2b0e4--a532f";
export var header = "video-module--header--67554";
export var iframeContainer = "video-module--iframe-container--b8177";
export var indicationSection = "video-module--indication-section--ab711";
export var left = "video-module--left--91b4f";
export var overlay = "video-module--overlay--07565";
export var removeMobile = "video-module--remove-mobile--6870f";
export var responsiveIframe = "video-module--responsive-iframe--8d886";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "video-module--secondLevelHero-module--banner__subTitle--8b2b5--f7593";
export var singleTimestamp = "video-module--single-timestamp--75131";
export var slider = "video-module--slider--a3a8f";
export var small = "video-module--small--c6490";
export var standard = "video-module--standard--5dce2";
export var switcher = "video-module--switcher--783bf";
export var timestamps = "video-module--timestamps--7bf78";
export var videoArea = "video-module--videoArea--782ee";
export var videoResource = "video-module--video-resource--93151";
export var videoTime = "video-module--videoTime--f1782";
export var video__iframe = "video-module--video__iframe--5abd9";
export var yell = "video-module--yell--c0e42";