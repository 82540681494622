import React from "react"
import * as styles from "./fullWidthImage.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

function fullWidthImage({ data }) {

  return (
    <div className={styles.fullWidthImage}>
      <GatsbyImage objectFit="contain" image={data.image} alt={data.imageTitle} />
    </div>
  )
}

export default fullWidthImage