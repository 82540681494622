import React from "react"
import { Link } from "gatsby"

import * as styles from "./button.module.scss"

function Button({ data }) {

  if (data.slug.includes("mailto:")) {

    return (
      <a className={styles.button} href={data.slug} dangerouslySetInnerHTML={{ __html: data.text.childMarkdownRemark.html }} />
    )

  } else {

    return (
      <Link className={styles.button} to={data.slug} dangerouslySetInnerHTML={{ __html: data.text.childMarkdownRemark.html }} />
    )

  }



}

export default Button